export const basciUrl = 'https://api.ebtoreview.top'




















//https://api1.apitask.cc
//https://api2.apitask.cc
//api.wpsaasapi.com


//export const basciUrl = 'https://api2.apitask.cc'